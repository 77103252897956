<template>
  <div class="SampleAdd">

    <van-sticky>
      <van-nav-bar
        @click-left="onClickLeft"
        left-text="返回"
        left-arrow
      >
        <template #right>
          <van-button plain id="btn-modify" type="danger" size="small" :to="{name:'SampleModify', query: {id:sample.id, record_id:sample.record_id}}">修 改</van-button>
        </template>
      </van-nav-bar>
    </van-sticky>



    <div class="info-desc">
      <van-icon name="todo-list" style="vertical-align: text-top;margin-right: 3px"/>
      <span>基本信息</span>
    </div>

    <van-cell-group>
      <van-cell title="销售姓名" :value=sample.saler_name />
      <van-cell title="合作伙伴" :value=sample.cooperation />
      <van-cell title="检测项目" :value=sample.detect_type />
      <van-cell title="检测单位" :value=sample.detect_institution />
      <van-cell title="是否收费" :value=sample.is_charge />
    </van-cell-group>

    <div class="info-desc">
      <van-icon name="cluster" style="vertical-align: text-top;margin-right: 3px"/>
      医院信息
    </div>
    <van-cell-group>
    <van-cell title="医院" :value=sample.hospital />
    <van-cell title="科室" :value=sample.department />
    <van-cell title="医生姓名" :value=sample.doctor_name />
    <van-cell title="医生电话" :value=sample.doctor_mobile />
    </van-cell-group>

    <div class="info-desc">
      <van-icon name="manager" style="vertical-align: text-top;margin-right: 3px"/>
      患者信息
    </div>


    <van-cell-group>
      <van-cell title="患者姓名" :value=sample.patient_name />
      <van-cell title="性别" :value=sample.gender />
      <van-cell title="年龄" :value=sample.age />
      <van-cell title="临床表现" :value=sample.clinical_feature />
      <van-cell title="临床诊断" :value=sample.diagnosis />
      <van-cell title="用药情况" :value=sample.medication />
    </van-cell-group>




    <div class="info-desc">
      <van-icon name="bag" style="vertical-align: text-top;margin-right: 3px"/>
      样本信息
    </div>

    <van-cell-group>
      <van-cell title="样本编号" :value=sample.knoin_no />
      <van-cell title="PK样本" :value=sample.is_compare />
      <van-cell title="样本类型" :value=sample.sample_type />
      <van-cell title="样本量" :value=sample.sample_size />
      <van-cell title="快递方式" :value=sample.convey_way />
      <van-cell title="快递单号" :value=sample.express_no />
      <van-cell title="车次号" :value=sample.railway_no />
      <van-cell title="采样日期" :value=sample.sampling_date />
      <van-cell title="送检日期" :value=sample.convey_date />
      <van-cell title="是否做结核qPCR" :value=sample.has_qpcr />
      <van-cell title="关注病原" :value=sample.pathogen />
      <van-cell title="培养结果" :value=sample.detect_result />
      <van-cell title="淋巴细胞" :value=sample.lymphocytes />
      <van-cell title="中性粒细胞" :value=sample.neutr_granule />
      <van-cell title="CRP" :value=sample.CRP />
      <van-cell title="PCT" :value=sample.PCT />
      <van-cell title="生物信息补充" :value=sample.bio_info />
      <van-cell title="备注" :value=sample.remarks />
    </van-cell-group>


    <van-cell-group>
      <van-cell title="附件" value="" />
      <div class="van-contact-edit">
      <van-image
        width="100"
        height="100"
        :src=sample.img_url
      />
      </div>
      </van-cell-group>



    <!--    <van-button type="primary" @click=scanCode>扫码</van-button>-->
    <!--    <van-button type="primary" @click=checkApi>查看接口</van-button>-->
  </div>

</template>

<script>

import { getSampleApi } from '@/api/sample'

export default {
  metaInfo: {
    title: '送检详情',
  },
  name: 'SampleDisplay',
  created() {
    this.getSample(this.$route.query.id)
  },
  data () {
    return {
      sample: {}
    }
  },
  methods: {
    getSample(id) {
      getSampleApi(id).then(res => {
        this.sample = res.data
        console.log(res.data.img_url)

        this.imgList.push({ url: res.data.img_url, isImage: true })

      })
    },

  }
}
</script>

<style scoped>
.van-nav-bar.van-hairline--bottom >>> .van-nav-bar__left > i {
  color: #00a29a;
}
.van-nav-bar.van-hairline--bottom >>> .van-nav-bar__left > span {
  color: #00a29a;
}
#btn-save {
  margin-right: 10px;
}

.option {
  font-size: 18px;
  margin-bottom: 14px
}
.option-two {
  font-size: 18px;
}
.info-desc {
  padding: 10px;
  color: #00a29a;
}

.van-button--plain.van-button--primary {
  color: #00a29a;
}
.van-button--primary {
  color: #fff;
  border: 1px solid #00a29a;
}
.van-cell >>> .van-icon-scan {
  color: #00a29a;
  font-size: 20px;
}

.van-button--primary.next {
  background-color: #00a29a;
}
</style>
